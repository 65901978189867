'use client';

import { useSelectedLayoutSegments } from 'next/navigation';

import useRouteWithLanguage from 'hooks/common/useRouteWithLanguage';
import useTranslations from 'hooks/common/useTranslations';
import Icon from 'components/atoms/Icon';
import Link from 'components/atoms/Link';
import { Routes } from 'common/constants/common';
import { AppStoreLogoContainer } from './styles';

const AppStoreLogo = () => {
  const { t } = useTranslations('common');
  const segments = useSelectedLayoutSegments();
  const { push } = useRouteWithLanguage();

  const goToMainPage = () => {
    const isItCheckoutPage = segments.includes('checkout');

    if (isItCheckoutPage) {
      return (window.location.href = Routes.HOME.pathname);
    }

    return push(Routes.HOME.pathname);
  };

  return (
    <AppStoreLogoContainer onClick={goToMainPage}>
      <Icon name="ucLogo" />
      <Icon name="vector" width={15} height={25} />
      <Link fontWeight="bold" size="base" href={Routes.HOME.pathname}>
        {t('appStore')}
      </Link>
    </AppStoreLogoContainer>
  );
};

export default AppStoreLogo;
